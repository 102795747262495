<template>
  <div>
    <div class="form-row">
      <sgv-input-text
        class="col-6"
        label="IP"
        v-model="formData.ip"
        :validations="[
          {text: 'IP Address!', value: $v.formData.ip.$dirty && !$v.formData.ip.ipAddress}
        ]">
      </sgv-input-text>

      <sgv-input-text
        class="col-6"
        label="Port"
        v-model="formData.port"
        :validations="[
          {text: 'ตัวเลขจำนวนเต็ม!', value: $v.formData.port.$dirty && !$v.formData.port.integer}
        ]">
      </sgv-input-text>

      <sgv-input-text
        class="col-6"
        label="Username"
        v-model="formData.username">
      </sgv-input-text>

      <sgv-input-text
        class="col-6"
        label="Password"
        v-model="formData.password">
      </sgv-input-text>

      <sgv-input-text
        class="col-6"
        label="Application Name"
        v-model="formData.applicationName">
      </sgv-input-text>

      <sgv-input-text
        class="col-6"
        label="Namespace"
        v-model="formData.namespace"
        :validations="[
          {text: 'ตัวเลขจำนวนเต็ม!', value: $v.formData.namespace.$dirty && !$v.formData.namespace.integer}
        ]">
      </sgv-input-text>

      <sgv-input-textarea
        class="col-12"
        label="Tags"
        placeholder="tag,name,scale"
        autoGrow
        v-model="formData.tags">
      </sgv-input-textarea>
    </div>

    <button
      class="btn btn-warning"
      @click="updateData">
      แก้ไข
    </button>
  </div>
</template>

<script>
import { helpers, integer, ipAddress } from 'vuelidate/lib/validators'
import { DETAIL_STATUS, CONFIG_DEVICE } from './graph'


export default {
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        ip: '',
        port: 0,
        username: '',
        password: '',
        applicationName: '',
        namespace: 0,
        tags: ''
      }
    }
  },
  validations: {
    formData: {
      ip: {
        ipAddress: (value) => {
          return !helpers.req(value) || ipAddress(value)
        }
      },
      port: {
        integer: (value) => {
          return !helpers.req(value) || integer(+value)
        }
      },
      namespace: {
        integer: (value) => {
          return !helpers.req(value) || integer(+value)
        }
      },
    }
  },
  methods: {
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'tags') {
          this.formData.tags = v.tags
          .map(tag => [tag.tag, tag.name, tag.scale])
          .join('\n')
        } else {
          this.formData[key] = v[key]
        }
      })
    },
    serializeTags (txt='') {
      return txt.split('\n')
      .filter(row => row)
      .map(row => row.split(','))
      .map(arr => ({
        tag: arr[0].trim(),
        name: arr[1].trim() || '',
        scale: +arr[2].trim() || 1
      }))
    },
    fetchData () {
      this.isLoaded = false
      this.$apollo.query({
        query: DETAIL_STATUS(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId
        },
        fetchPolicy: 'no-cache'
      }).then(res => {
        const status = res.data.status
        this.setFormData(status)
      }).catch(() => {
        this.errorMessage = 'ไม่สามารถเชื่อมต่อได้'
      }).finally(() => {
        this.isLoaded = true
      })
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CONFIG_DEVICE(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
          input: {
            ip: this.formData.ip,
            port: +this.formData.port,
            username: this.formData.username,
            password: this.formData.password,
            applicationName: this.formData.applicationName,
            namespace: +this.formData.namespace,
            tags: this.serializeTags(this.formData.tags)
          }
        }
      }).then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.$v.formData.$reset()
      }).catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="css" scoped>
</style>
